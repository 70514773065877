import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49')
];

export const server_loads = [2,4,7,6];

export const dictionary = {
		"/(app)": [~8,[2],[3]],
		"/(app)/admin": [~9,[2,4],[3]],
		"/(app)/admin/application-settings": [10,[2,4],[3]],
		"/(app)/admin/contact": [~11,[2,4],[3]],
		"/(app)/admin/contact/chat/[id]": [~12,[2,4],[3]],
		"/(app)/admin/dropin": [~13,[2,4],[3]],
		"/(app)/admin/dropin/chat/[id]": [~14,[2,4],[3]],
		"/(app)/admin/exercises": [~15,[2,4],[3]],
		"/(app)/admin/exercises/new": [~17,[2,4],[3]],
		"/(app)/admin/exercises/[id]": [~16,[2,4],[3]],
		"/(app)/admin/try-contact": [~18,[2,4],[3]],
		"/(app)/admin/try-contact/chat/[id]": [~19,[2,4],[3]],
		"/(app)/admin/upcoming-events": [~20,[2,4],[3]],
		"/(app)/admin/users": [~21,[2,4],[3]],
		"/(app)/book-training": [22,[2],[3]],
		"/(app)/calendar": [23,[2],[3]],
		"/chat/not-found": [48],
		"/chat/[id]": [~47],
		"/init-password": [~49],
		"/(app)/install-app": [24,[2],[3]],
		"/(auth)/login": [~42,[7]],
		"/(auth)/logout": [43,[7]],
		"/(app)/month-calendar": [25,[2],[3]],
		"/(app)/my-programs": [26,[2],[3]],
		"/(app)/my-programs/[id]": [27,[2],[3]],
		"/(app)/my-workouts": [~28,[2],[3]],
		"/(app)/my-workouts/[id]": [~29,[2],[3]],
		"/(auth)/password/request": [~44,[7]],
		"/(auth)/password/reset": [~45,[7]],
		"/(app)/personal-best": [~30,[2],[3]],
		"/(app)/personal-best/[id]": [~31,[2],[3]],
		"/(app)/profile/notifications": [~32,[2,5],[3]],
		"/(app)/profile/profile": [~33,[2,5],[3]],
		"/(app)/profile/security": [~34,[2,5],[3]],
		"/(app)/program": [35,[2],[3]],
		"/(auth)/register-90dk3eiozMLKSzhsj": [~46,[7]],
		"/(app)/user-workouts/[id]/finalize": [~36,[2,6],[3]],
		"/(app)/user-workouts/[id]/sections/[sectionId]": [~37,[2,6],[3]],
		"/(app)/user-workouts/[id]/start": [38,[2,6],[3]],
		"/(app)/users/[id]": [~39,[2],[3]],
		"/(app)/workouts": [~40,[2],[3]],
		"/(app)/workouts/[id]": [~41,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.js';